/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

const Skills = () => {

  return (
    <div className="container container-max-md pt-5">
      <h2 className="text-600 h5 m-0">Skills</h2>

      <article className="row gx-0 gy-5 justify-content-center justify-content-sm-between py-4 css-skill">
        <div className="col-sm d-flex flex-column justify-content-center">
          <div className="d-flex align-items-center mb-3">
            <div className="py-3 px-4 rounded bg-800 logo-of-skill">
              <StaticImage
                className="borderless"
                layout="constrained"
                formats={["AUTO", "WEBP", "AVIF"]}
                src="../images/logo/CSS3_logo_and_wordmark.svg"
                height={68}
                quality={95}
                alt="CSS (Cascading Style Sheets)"
              />
            </div>
            <div className="ms-4">
              <h3 className="text-100 fw-bold h2 mb-0">CSS</h3>
              <p className="text-600 small mb-0">Cascading Style Sheets</p>
            </div>
          </div>
          <p className="=xs text-400 fst-italic mb-0 mt-1">I love developing CSS, the most important component of UI and UX. I am very experienced in product development with CSS.</p>
        </div>
        <div className="col-auto">
          <div className="d-flex justify-content-center">
            <div className="tube">
              <div className="shine"></div>
              <div className="body">
                <div className="liquid">
                  <div className="percentage"></div>
                </div>
              </div>
              <div className="meter">
                <div>100</div>
                <div>80</div>
                <div>60</div>
                <div>40</div>
                <div>20</div>
              </div>
              <div className="bubbles">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          </div>
        </div>
      </article>

      <article className="row gx-0 gy-5 justify-content-center justify-content-sm-between py-4 html-skill">
        <div className="col-sm d-flex flex-column justify-content-center">
          <div className="d-flex align-items-center mb-3">
            <div className="py-3 px-4 rounded bg-800 logo-of-skill">
              <StaticImage
                className="borderless"
                layout="constrained"
                formats={["AUTO", "WEBP", "AVIF"]}
                src="../images/logo/HTML5_logo_and_wordmark.svg"
                height={68}
                quality={95}
                alt="HTML (Hypertext Markup Language)"
              />
            </div>
            <div className="ms-4">
              <h3 className="text-100 fw-bold h2 mb-0">HTML</h3>
              <p className="text-600 small mb-0">Hypertext Markup Language</p>
            </div>
          </div>
          <p className="=xs text-400 fst-italic mb-0 mt-1">I can do professional work on HTML, which is a kind of standard used to create web pages.</p>
        </div>
        <div className="col-auto">
          <div className="d-flex justify-content-center">
            <div className="tube">
              <div className="shine"></div>
              <div className="body">
                <div className="liquid">
                  <div className="percentage"></div>
                </div>
              </div>
              <div className="meter">
                <div>100</div>
                <div>80</div>
                <div>60</div>
                <div>40</div>
                <div>20</div>
              </div>
              <div className="bubbles">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          </div>
        </div>
      </article>

      <article className="row gx-0 gy-5 justify-content-center justify-content-sm-between py-4 js-skill">
        <div className="col-sm d-flex flex-column justify-content-center">
          <div className="d-flex align-items-center mb-3">
            <div className="py-3 px-4 rounded bg-800 logo-of-skill">
              <StaticImage
                className="borderless"
                layout="constrained"
                formats={["AUTO", "WEBP", "AVIF"]}
                src="../images/logo/Unofficial_JavaScript_logo_2.svg"
                height={68}
                quality={95}
                alt="JS - JavaScript (Scripting Language)"
              />
            </div>
            <div className="ms-4">
              <h3 className="text-100 fw-bold h2 mb-0">JavaScript</h3>
              <p className="text-600 small mb-0">Scripting Language</p>
            </div>
          </div>
          <p className="=xs text-400 fst-italic mb-0 mt-1">I often use JavaScript, which is the biggest helper of websites on the client side, and produce quality work.</p>
        </div>
        <div className="col-auto">
          <div className="d-flex justify-content-center">
            <div className="tube">
              <div className="shine"></div>
              <div className="body">
                <div className="liquid">
                  <div className="percentage"></div>
                </div>
              </div>
              <div className="meter">
                <div>100</div>
                <div>80</div>
                <div>60</div>
                <div>40</div>
                <div>20</div>
              </div>
              <div className="bubbles">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          </div>
        </div>
      </article>

      <div className="row g-5">
        <div className="col-md-6">
          <article className="row gx-0 gy-5 justify-content-center justify-content-sm-between py-4 bootstrap-skill">
            <div className="col-sm d-flex flex-column justify-content-center">
              <div className="d-flex align-items-center mb-3">
                <div className="py-3 px-4 rounded bg-800 logo-of-skill">
                  <StaticImage
                    className="borderless"
                    layout="constrained"
                    formats={["AUTO", "WEBP", "AVIF"]}
                    src="../images/logo/Bootstrap_logo.svg"
                    height={32}
                    quality={95}
                    alt="Bootstrap (CSS Framework)"
                  />
                </div>
                <div className="ms-3">
                  <h3 className="text-100 fw-bold h5 mb-0 lh-1.35">Bootstrap</h3>
                  <p className="text-600 =xs mb-0">CSS Framework</p>
                </div>
                <div className="d-flex justify-content-center ms-auto">
                  <div className="tube small">
                    <div className="shine"></div>
                    <div className="body">
                      <div className="liquid">
                        <div className="percentage"></div>
                      </div>
                    </div>
                    <div className="meter">
                    </div>
                    <div className="bubbles">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                </div>
              </div>
              <p className="=xs text-400 fst-italic mb-0 mt-1">A great CSS framework for making great websites. I can develop responsive, mobile-first and cross-browser compatible projects with Bootstrap.</p>
            </div>
          </article>
        </div>
        <div className="col-md-6">
          <article className="row gx-0 gy-5 justify-content-center justify-content-sm-between py-4 jquery-skill">
            <div className="col-sm d-flex flex-column justify-content-center">
              <div className="d-flex align-items-center mb-3">
                <div className="py-3 px-4 rounded bg-800 logo-of-skill">
                  <StaticImage
                    className="borderless"
                    layout="constrained"
                    formats={["AUTO", "WEBP", "AVIF"]}
                    src="../images/logo/JQuery-Logo.svg"
                    height={32}
                    quality={95}
                    alt="jQuery (JavaScript Library)"
                  />
                </div>
                <div className="ms-3">
                  <h3 className="text-100 fw-bold h5 mb-0 lh-1.35">jQuery</h3>
                  <p className="text-600 =xs mb-0">JavaScript Library</p>
                </div>
                <div className="d-flex justify-content-center ms-auto">
                  <div className="tube small">
                    <div className="shine"></div>
                    <div className="body">
                      <div className="liquid">
                        <div className="percentage"></div>
                      </div>
                    </div>
                    <div className="meter"></div>
                    <div className="bubbles">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                </div>
              </div>
              <p className="=xs text-400 fst-italic mb-0 mt-1">Despite many emerging frameworks, jQuery is still the library of choice by many systems. I can prepare very high quality scripts with jQuery.</p>
            </div>
          </article>
        </div>
      </div>

      <div className="d-flex justify-content-center py-5">
        <label htmlFor="show-more-skills" className="d-inline-flex py-2 px-4 bg-700 hover--bg-600 rounded text-white cup user-select-none">Show/hide other skills</label>
      </div>
      <input className="d-none" id="show-more-skills" type="checkbox" aria-hidden="true" hidden />

      <article className="hidden-skill row gx-0 gy-5 justify-content-center justify-content-sm-between py-4 php-skill">
        <div className="col-sm d-flex flex-column justify-content-center">
          <div className="d-flex align-items-center mb-3">
            <div className="py-3 px-4 rounded bg-800 logo-of-skill">
              <StaticImage
                className="borderless"
                layout="constrained"
                formats={["AUTO", "WEBP", "AVIF"]}
                src="../images/logo/new-php-logo.svg"
                height={68}
                quality={95}
                alt="PHP (Hypertext Preprocessor)"
              />
            </div>
            <div className="ms-4">
              <h3 className="text-100 fw-bold h2 mb-0">PHP</h3>
              <p className="text-600 small mb-0">Hypertext Preprocessor</p>
            </div>
          </div>
          <p className="=xs text-400 fst-italic mb-0 mt-1">I can develop advanced software with PHP, which is a server-side scripting and programming language.</p>
        </div>
        <div className="col-auto">
          <div className="d-flex justify-content-center">
            <div className="tube">
              <div className="shine"></div>
              <div className="body">
                <div className="liquid">
                  <div className="percentage"></div>
                </div>
              </div>
              <div className="meter">
                <div>100</div>
                <div>80</div>
                <div>60</div>
                <div>40</div>
                <div>20</div>
              </div>
              <div className="bubbles">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          </div>
        </div>
      </article>

      <div className="hidden-skill row g-5">
        <div className="col-md-6">
          <article className="row gx-0 gy-5 justify-content-center justify-content-sm-between py-4 git-skill">
            <div className="col-sm d-flex flex-column justify-content-center">
              <div className="d-flex align-items-center mb-3">
                <div className="py-3 px-4 rounded bg-800 logo-of-skill">
                  <StaticImage
                    className="borderless"
                    layout="constrained"
                    formats={["AUTO", "WEBP", "AVIF"]}
                    src="../images/logo/Git-logo.svg"
                    height={32}
                    quality={95}
                    alt="Git (Version Control System)"
                  />
                </div>
                <div className="ms-3">
                  <h3 className="text-100 fw-bold h5 mb-0 lh-1.35">Git</h3>
                  <p className="text-600 =xs mb-0">Version Control System</p>
                </div>
                <div className="d-flex justify-content-center ms-auto">
                  <div className="tube small">
                    <div className="shine"></div>
                    <div className="body">
                      <div className="liquid">
                        <div className="percentage"></div>
                      </div>
                    </div>
                    <div className="meter"></div>
                    <div className="bubbles">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                </div>
              </div>
              <p className="=xs text-400 fst-italic mb-0 mt-1">In the projects I develop, I usually prefer to do version control using "Git".</p>
            </div>
          </article>
        </div>
        <div className="col-md-6">
          <article className="row gx-0 gy-5 justify-content-center justify-content-sm-between py-4 github-skill">
            <div className="col-sm d-flex flex-column justify-content-center">
              <div className="d-flex align-items-center mb-3">
                <div className="py-3 px-4 rounded bg-800 logo-of-skill">
                  <StaticImage
                    className="borderless"
                    layout="constrained"
                    formats={["AUTO", "WEBP", "AVIF"]}
                    src="../images/logo/Octicons-mark-github.svg"
                    height={32}
                    quality={95}
                    alt="Github (Development Platform)"
                  />
                </div>
                <div className="ms-3">
                  <h3 className="text-100 fw-bold h5 mb-0 lh-1.35">Github</h3>
                  <p className="text-600 =xs mb-0">Development Platform</p>
                </div>
                <div className="d-flex justify-content-center ms-auto">
                  <div className="tube small">
                    <div className="shine"></div>
                    <div className="body">
                      <div className="liquid">
                        <div className="percentage"></div>
                      </div>
                    </div>
                    <div className="meter"></div>
                    <div className="bubbles">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                </div>
              </div>
              <p className="=xs text-400 fst-italic mb-0 mt-1">I create, develop and deploy projects on Github, a kind of development platform that uses "Git" version control system.</p>
            </div>
          </article>
        </div>
      </div>

      <article className="hidden-skill row gx-0 gy-5 justify-content-center justify-content-sm-between py-4 wordpress-skill">
        <div className="col-sm d-flex flex-column justify-content-center">
          <div className="d-flex align-items-center mb-3">
            <div className="py-3 px-4 rounded bg-800 logo-of-skill">
              <StaticImage
                className="borderless"
                layout="constrained"
                formats={["AUTO", "WEBP", "AVIF"]}
                src="../images/logo/WordPress.svg"
                height={70}
                quality={95}
                alt="WordPress (Content Management System)"
              />
            </div>
            <div className="ms-4">
              <h3 className="text-100 fw-bold h2 mb-0">WordPress</h3>
              <p className="text-600 small mb-0">Content Management System</p>
            </div>
          </div>
          <p className="=xs text-400 fst-italic mb-0 mt-1">WordPress is the best and most popular CMS available for development due to its open source and nature. With WordPress, I can create professional projects that are personalized and serve different purposes.</p>
        </div>
        <div className="col-auto">
          <div className="d-flex justify-content-center">
            <div className="tube">
              <div className="shine"></div>
              <div className="body">
                <div className="liquid">
                  <div className="percentage"></div>
                </div>
              </div>
              <div className="meter">
                <div>100</div>
                <div>80</div>
                <div>60</div>
                <div>40</div>
                <div>20</div>
              </div>
              <div className="bubbles">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          </div>
        </div>
      </article>      

      <div className="hidden-skill row g-5">
        <div className="col-md-6">
          <article className="row gx-0 gy-5 justify-content-center justify-content-sm-between py-4 composer-skill">
            <div className="col-sm d-flex flex-column justify-content-center">
              <div className="d-flex align-items-center mb-3">
                <div className="py-3 px-4 rounded bg-800 logo-of-skill">
                  <StaticImage
                    className="borderless"
                    layout="constrained"
                    formats={["AUTO", "WEBP", "AVIF"]}
                    src="../images/logo/logo-composer-transparent3.png"
                    height={46}
                    quality={95}
                    alt="Composer (The PHP Dependency Manager)"
                  />
                </div>
                <div className="ms-3">
                  <h3 className="text-100 fw-bold h5 mb-0 lh-1.35">Composer</h3>
                  <p className="text-600 =xs mb-0">The PHP Dependency Manager</p>
                </div>
                <div className="d-flex justify-content-center ms-auto">
                  <div className="tube small">
                    <div className="shine"></div>
                    <div className="body">
                      <div className="liquid">
                        <div className="percentage"></div>
                      </div>
                    </div>
                    <div className="meter"></div>
                    <div className="bubbles">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                </div>
              </div>
              <p className="=xs text-400 fst-italic mb-0 mt-1">Although I'm not a fan of dependencies, I usually use some large libraries in PHP projects.</p>
            </div>
          </article>
        </div>
        <div className="col-md-6">
          <article className="row gx-0 gy-5 justify-content-center justify-content-sm-between py-4 packagist-skill">
            <div className="col-sm d-flex flex-column justify-content-center">
              <div className="d-flex align-items-center mb-3">
                <div className="py-3 px-4 rounded bg-800 logo-of-skill">
                  <StaticImage
                    className="borderless"
                    layout="constrained"
                    formats={["AUTO", "WEBP", "AVIF"]}
                    src="../images/logo/packagist.png"
                    height={46}
                    quality={95}
                    alt="Packagist (The PHP Package Repository)"
                  />
                </div>
                <div className="ms-3">
                  <h3 className="text-100 fw-bold h5 mb-0 lh-1.35">Packagist</h3>
                  <p className="text-600 =xs mb-0">The PHP Package Repository</p>
                </div>
                <div className="d-flex justify-content-center ms-auto">
                  <div className="tube small">
                    <div className="shine"></div>
                    <div className="body">
                      <div className="liquid">
                        <div className="percentage"></div>
                      </div>
                    </div>
                    <div className="meter"></div>
                    <div className="bubbles">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                </div>
              </div>
              <p className="=xs text-400 fst-italic mb-0 mt-1">I prefer to publish my open source PHP projects on "packagist.org".</p>
            </div>
          </article>
        </div>
      </div>

      <article className="hidden-skill row gx-0 gy-5 justify-content-center justify-content-sm-between py-4 figma-skill">
        <div className="col-sm d-flex flex-column justify-content-center">
          <div className="d-flex align-items-center mb-3">
            <div className="py-3 px-4 rounded bg-800 logo-of-skill">
              <StaticImage
                className="borderless"
                layout="constrained"
                formats={["AUTO", "WEBP", "AVIF"]}
                src="../images/logo/Figma-1-logo.png"
                height={68}
                quality={95}
                alt="Figma (Cloud Based Editor and Tool)"
              />
            </div>
            <div className="ms-4">
              <h3 className="text-100 fw-bold h2 mb-0">Figma</h3>
              <p className="text-600 small mb-0">Cloud Based Editor and Tool</p>
            </div>
          </div>
          <p className="=xs text-400 fst-italic mb-0 mt-1">I love using Figma especially in big projects and prototyping.</p>
        </div>
        <div className="col-auto">
          <div className="d-flex justify-content-center">
            <div className="tube">
              <div className="shine"></div>
              <div className="body">
                <div className="liquid">
                  <div className="percentage"></div>
                </div>
              </div>
              <div className="meter">
                <div>100</div>
                <div>80</div>
                <div>60</div>
                <div>40</div>
                <div>20</div>
              </div>
              <div className="bubbles">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          </div>
        </div>
      </article>

      <div className="hidden-skill row g-5">
        <div className="col-md-6">
          <article className="row gx-0 gy-5 justify-content-center justify-content-sm-between py-4 illustrator-skill">
            <div className="col-sm d-flex flex-column justify-content-center">
              <div className="d-flex align-items-center mb-3">
                <div className="py-3 px-4 rounded bg-800 logo-of-skill">
                  <StaticImage
                    className="borderless"
                    layout="constrained"
                    formats={["AUTO", "WEBP", "AVIF"]}
                    src="../images/logo/illustrator.svg"
                    height={46}
                    quality={95}
                    alt="Illustrator CC (Software)"
                  />
                </div>
                <div className="ms-3">
                  <h3 className="text-100 fw-bold h5 mb-0 lh-1.35">Illustrator CC</h3>
                  <p className="text-600 =xs mb-0">Software</p>
                </div>
                <div className="d-flex justify-content-center ms-auto">
                  <div className="tube small">
                    <div className="shine"></div>
                    <div className="body">
                      <div className="liquid">
                        <div className="percentage"></div>
                      </div>
                    </div>
                    <div className="meter"></div>
                    <div className="bubbles">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                </div>
              </div>
              <p className="=xs text-400 fst-italic mb-0 mt-1">I prefer Adobe Illustrator CC especially for UI designs.</p>
            </div>
          </article>
        </div>
        <div className="col-md-6">
          <article className="row gx-0 gy-5 justify-content-center justify-content-sm-between py-4 photoshop-skill">
            <div className="col-sm d-flex flex-column justify-content-center">
              <div className="d-flex align-items-center mb-3">
                <div className="py-3 px-4 rounded bg-800 logo-of-skill">
                  <StaticImage
                    className="borderless"
                    layout="constrained"
                    formats={["AUTO", "WEBP", "AVIF"]}
                    src="../images/logo/Adobe_Photoshop_CC_icon.svg"
                    height={52}
                    quality={95}
                    alt="Photoshop CC (Software)"
                  />
                </div>
                <div className="ms-3">
                  <h3 className="text-100 fw-bold h5 mb-0 lh-1.35">Photoshop CC</h3>
                  <p className="text-600 =xs mb-0">Software</p>
                </div>
                <div className="d-flex justify-content-center ms-auto">
                  <div className="tube small">
                    <div className="shine"></div>
                    <div className="body">
                      <div className="liquid">
                        <div className="percentage"></div>
                      </div>
                    </div>
                    <div className="meter"></div>
                    <div className="bubbles">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                </div>
              </div>
              <p className="=xs text-400 fst-italic mb-0 mt-1">Adobe Photoshop CC is a big part of our life. It's a fact and I use it often.</p>
            </div>
          </article>
        </div>
        <div className="col-md-6">
          <article className="row gx-0 gy-5 justify-content-center justify-content-sm-between py-4 aftereffects-skill">
            <div className="col-sm d-flex flex-column justify-content-center">
              <div className="d-flex align-items-center mb-3">
                <div className="py-3 px-4 rounded bg-800 logo-of-skill">
                  <StaticImage
                    className="borderless"
                    layout="constrained"
                    formats={["AUTO", "WEBP", "AVIF"]}
                    src="../images/logo/Adobe_After_Effects_CC_icon.svg"
                    height={52}
                    quality={95}
                    alt="After Effects CC (Software)"
                  />
                </div>
                <div className="ms-3">
                  <h3 className="text-100 fw-bold h5 mb-0 lh-1.35">After Effects CC</h3>
                  <p className="text-600 =xs mb-0">Software</p>
                </div>
                <div className="d-flex justify-content-center ms-auto">
                  <div className="tube small">
                    <div className="shine"></div>
                    <div className="body">
                      <div className="liquid">
                        <div className="percentage"></div>
                      </div>
                    </div>
                    <div className="meter"></div>
                    <div className="bubbles">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                </div>
              </div>
              <p className="=xs text-400 fst-italic mb-0 mt-1">I love using Adobe After Effects CC and produce animated products.</p>
            </div>
          </article>
        </div>
        <div className="col-md-6">
          <article className="row gx-0 gy-5 justify-content-center justify-content-sm-between py-4 xd-skill">
            <div className="col-sm d-flex flex-column justify-content-center">
              <div className="d-flex align-items-center mb-3">
                <div className="py-3 px-4 rounded bg-800 logo-of-skill">
                  <StaticImage
                    className="borderless"
                    layout="constrained"
                    formats={["AUTO", "WEBP", "AVIF"]}
                    src="../images/logo/Adobe_XD_CC_icon.svg"
                    height={52}
                    quality={95}
                    alt="XD CC (Software)"
                  />
                </div>
                <div className="ms-3">
                  <h3 className="text-100 fw-bold h5 mb-0 lh-1.35">XD CC</h3>
                  <p className="text-600 =xs mb-0">Software</p>
                </div>
                <div className="d-flex justify-content-center ms-auto">
                  <div className="tube small">
                    <div className="shine"></div>
                    <div className="body">
                      <div className="liquid">
                        <div className="percentage"></div>
                      </div>
                    </div>
                    <div className="meter"></div>
                    <div className="bubbles">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                </div>
              </div>
              <p className="=xs text-400 fst-italic mb-0 mt-1">I like to use Adobe XD CC even though it has lost its throne to Figma for me.</p>
            </div>
          </article>
        </div>
      </div>      

      <article className="hidden-skill row gx-0 gy-5 justify-content-center justify-content-sm-between py-4 nodejs-skill">
        <div className="col-sm d-flex flex-column justify-content-center">
          <div className="d-flex align-items-center mb-3">
            <div className="py-3 px-4 rounded bg-800 logo-of-skill">
              <StaticImage
                className="borderless"
                layout="constrained"
                formats={["AUTO", "WEBP", "AVIF"]}
                src="../images/logo/Node.js_logo.png"
                height={68}
                quality={95}
                alt="Node.js (JavaScript Runtime Built)"
              />
            </div>
            <div className="ms-4">
              <h3 className="text-100 fw-bold h2 mb-0">Node.js</h3>
              <p className="text-600 small mb-0">JavaScript Runtime Built</p>
            </div>
          </div>
          <p className="=xs text-400 fst-italic mb-0 mt-1">I continue to develop myself in Node.js, which is used to run Scripts on the server side.</p>
        </div>
        <div className="col-auto">
          <div className="d-flex justify-content-center">
            <div className="tube">
              <div className="shine"></div>
              <div className="body">
                <div className="liquid">
                  <div className="percentage"></div>
                </div>
              </div>
              <div className="meter">
                <div>100</div>
                <div>80</div>
                <div>60</div>
                <div>40</div>
                <div>20</div>
              </div>
              <div className="bubbles">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          </div>
        </div>
      </article>

      <div className="hidden-skill row g-5">
        <div className="col-md-6">
          <article className="row gx-0 gy-5 justify-content-center justify-content-sm-between py-4 react-skill">
            <div className="col-sm d-flex flex-column justify-content-center">
              <div className="d-flex align-items-center mb-3">
                <div className="py-3 px-4 rounded bg-800 logo-of-skill">
                  <StaticImage
                    className="borderless"
                    layout="constrained"
                    formats={["AUTO", "WEBP", "AVIF"]}
                    src="../images/logo/React.png"
                    height={32}
                    quality={95}
                    alt="React (JavaScript Library)"
                  />
                </div>
                <div className="ms-3">
                  <h3 className="text-100 fw-bold h5 mb-0 lh-1.35">React</h3>
                  <p className="text-600 =xs mb-0">JavaScript Library</p>
                </div>
                <div className="d-flex justify-content-center ms-auto">
                  <div className="tube small">
                    <div className="shine"></div>
                    <div className="body">
                      <div className="liquid">
                        <div className="percentage"></div>
                      </div>
                    </div>
                    <div className="meter"></div>
                    <div className="bubbles">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                </div>
              </div>
              <p className="=xs text-400 fst-italic mb-0 mt-1">I am very interested in React and I am constantly trying to improve myself.</p>
            </div>
          </article>
        </div>
        <div className="col-md-6">
          <article className="row gx-0 gy-5 justify-content-center justify-content-sm-between py-4 vuejs-skill">
            <div className="col-sm d-flex flex-column justify-content-center">
              <div className="d-flex align-items-center mb-3">
                <div className="py-3 px-4 rounded bg-800 logo-of-skill">
                  <StaticImage
                    className="borderless"
                    layout="constrained"
                    formats={["AUTO", "WEBP", "AVIF"]}
                    src="../images/logo/vue.svg"
                    height={50}
                    quality={95}
                    alt="Vue.js"
                  />
                </div>
                <div className="ms-3">
                  <h3 className="text-100 fw-bold h5 mb-0 lh-1.35">Vue.js</h3>
                  <p className="text-600 =xs mb-0">The Progressive JavaScript Framework</p>
                </div>
                <div className="d-flex justify-content-center ms-auto">
                  <div className="tube small">
                    <div className="shine"></div>
                    <div className="body">
                      <div className="liquid">
                        <div className="percentage"></div>
                      </div>
                    </div>
                    <div className="meter"></div>
                    <div className="bubbles">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                </div>
              </div>
              <p className="=xs text-400 fst-italic mb-0 mt-1">I am very interested in Vue.js and I am constantly trying to improve myself.</p>
            </div>
          </article>
        </div>
      </div>

    </div>
  )
}

export default Skills
